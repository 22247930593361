import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Cookies } from '../../../shared/constants/cookies';
import { UserType } from '../../../shared/models/clock';
import { GallerySeat } from '../models/gallery-seat';

// services
import { CookieService } from 'ngx-cookie-service';
import { WebApiService } from '../../../shared/services/web-api.service';
import { HttpTransportType, HubConnection, HubConnectionBuilder, IReconnectPolicy, JsonHubProtocol, LogLevel } from '@aspnet/signalr';
import { ConfigService } from '../../../shared/services/config.service';
import { ErrorService } from '../../../shared/services/error.service';
import { TokenService } from '../../../shared/services/token.service';
import { ArdsInfo } from '../models/ards-info';
import { Guid } from 'guid-typescript';

const DEFAULT_RECONNECT_TIMEOUT_MS = 5000;

@Injectable()
export class GalleryService {

  private apiPath: string;
  private hubConnection: HubConnection;
  private isStopped = true;
  private connectionGuid: Guid;

  @Output('ardsInfo') ardsInfo: EventEmitter<ArdsInfo> = new EventEmitter();
  @Output('isAutheticated') isAutheticated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private appSettings: ApplicationSettings,
              private webApiService: WebApiService,
              private errorService: ErrorService,
              private configService: ConfigService,
              private cookieService: CookieService,
              private tokenService: TokenService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
    this.connectionGuid = Guid.create();
  }

  async initSignalR() {
    this.stop();
    try {
      let config = await lastValueFrom(this.webApiService.getSingle(`${this.getUrlWithoutTrailingSlash(this.appSettings.clockURL)}/aucxis.config.json?d=${Date.now()}`));
      let socketUrl = this.configService.getClockServiceUrl(config);
      this.createHubConnection(socketUrl);
    }
    catch (ex) {
      this.errorService.show(ex);
      console.error(ex);
    }
  }

  stop() {
    this.isStopped = true;
    this.isAutheticated.emit(false);
    if (this.hubConnection) {
      // Remove handlers
      this.hubConnection.off('ARDSInfo');
      this.hubConnection.off('UserAuthorizationResponse');
      // Stop connection
      this.hubConnection.stop();
    }
  }

  getARDSInfo(clockId: number) {
    // Ignore request if user is not authenticated
    if (!this.isAutheticated)
      return;

    // Call Hub message
    this.hubConnection.send('ActorMessage', 'ARDSInfoRequest', { clockId: clockId, connectionGuid: this.connectionGuid.toString() });
  }

  private createHubConnection(socketUrl: string) {
    const userId = this.cookieService.get(Cookies.USER_ID_COOKIE);
    const url = `${socketUrl}auction?userId=${userId}&type=${UserType.ADMINISTRATOR}&clockId=0`;

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, { transport: HttpTransportType.WebSockets, skipNegotiation: true, })
      .configureLogging(LogLevel.Error)
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect(<IReconnectPolicy>{
        nextRetryDelayInMilliseconds: this.nextRetryDelayInMilliseconds
      })
      .build();

    this.hubConnection.onreconnected((connectionId: string) => {
      this.authenticate();
    });

    // Register method handlers
    this.hubConnection.on('ARDSInfo', (response: ArdsInfo) => { this.ardsInfo.emit(response) });

    this.hubConnection.on('UserAuthorizationResponse', (response: any) => {
      this.isAutheticated.emit(response.isAuthenticationSuccess);
    });

    this.isStopped = false;

    this.hubConnection.start().then(_ => this.authenticate());
  }

  private authenticate() {
    const userId = this.cookieService.get(Cookies.USER_ID_COOKIE);
    const token = this.tokenService.getToken();
    const connectionGuid = this.connectionGuid.toString();

    // Request auth from engine
    this.hubConnection.send('ActorMessage', 'UserAuthorizationRequest', { userId, token, connectionGuid });
  }

  private nextRetryDelayInMilliseconds(previousRetryCount: number, elapsedMilliseconds: number) {
    if (this.isStopped)
      return null;
    else {
      console.log(`Reconnecting in ${DEFAULT_RECONNECT_TIMEOUT_MS}ms`);
      return DEFAULT_RECONNECT_TIMEOUT_MS;
    }
  }

  getGallerySeats(auctionClusterId: number): Observable<Array<GallerySeat>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionClusterId + '/galleryseat');
  }

  getGallerySeat(auctionId: number, gallerySeatId: number): Observable<GallerySeat> {
    return this.webApiService.get(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeatId);
  }

  save(auctionId: number, gallerySeat: GallerySeat): Observable<any> {
    return this.webApiService.save<GallerySeat>(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeat);
  }

  edit(auctionId: number, gallerySeat: GallerySeat): Observable<any> {
    return this.webApiService.edit<GallerySeat>(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeat.gallerySeatId, gallerySeat);
  }

  delete(auctionId: number, gallerySeatId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/auction/' + auctionId + '/galleryseat', gallerySeatId);
  }

  private getUrlWithoutTrailingSlash(url: string) {
    return url.replace(/\/+$/, "");
  }
}
