export class ArdsInfo {
  clockId: number;
  connected: boolean;
  galleryType: string;
  gallerySize: number;
  version: string;
  auctionroomBuyers: Array<AuctionroomBuyer>;
}

export class AuctionroomBuyer {
  buyerId: number;
  seatNumbers: Array<number>;
}

export class GalleryType {
  static readonly RC6 = 'RC6';
  static readonly FortyEightDI = 'FortyEightDI';
  static readonly CTIF = 'CTIF';
  static readonly RC6Simulator = 'RC6Simulator';
}
