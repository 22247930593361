import { Component, OnInit, ViewChildren, AfterViewInit, Injector } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, timer } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

// models
import { CollapsableSectionComponent } from '../components/collapsable-section/collapsable-section.component';

// services
import { AdditionalMenuItemsService } from '../services/additional-menu-items.service';
import { AuthService } from '../services/auth.service';
import { ErrorService } from '../services/error.service';
import { LanguageService } from '../services/language.service';
import { TokenService } from '../services/token.service';

@Component({
  selector: 'content-component',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, AfterViewInit {

  @ViewChildren(CollapsableSectionComponent) collapsableSections: Array<CollapsableSectionComponent>;

  public id: number;
  public baseUrl: string;

  private cookies: CookieService;
  public router: Router;
  public route: ActivatedRoute;
  public tokenService: TokenService;
  public additionalMenuItemsService: AdditionalMenuItemsService;
  public errorService: ErrorService;
  public authenticationService: AuthService;
  public languageService: LanguageService;

  public expanded: boolean;
  public pin: boolean;
  public kosmosURL: string;
  timer = timer(1);

  constructor(private injector: Injector) {
    this.cookies = this.injector.get(CookieService);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.authenticationService = this.injector.get(AuthService);
    this.tokenService = this.injector.get(TokenService);
    this.additionalMenuItemsService = this.injector.get(AdditionalMenuItemsService);
    this.errorService = this.injector.get(ErrorService);
    this.languageService = this.injector.get(LanguageService);

    this.baseUrl = window.location.href;

    // handing route activation when it is not triggered by navigation itself
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.timer.subscribe(
          () => {
            this.collapsableSections.forEach(section => {
              if (event.url.includes('/' + section.sectionId + '/') && section.sectionId !== undefined) {
                section.onChange();
              }
            });
          });

        this.route.firstChild.params.subscribe(params => {
          this.id = +params['id'];
        });
      });
  }

  ngOnInit() {
    this.pin = this.cookies.get('navPin') == 'true' || this.cookies.get('navPin') == null; // tslint:disable-line:triple-equals
    this.pin = true;
    this.expanded = this.pin;

    this.languageService.getLanguages().subscribe(languages => {
      var currentLanguage = this.languageService.getCurrentLanguage(languages);
      this.kosmosURL = 'https://e-trade.aucxis.com/';

      switch (currentLanguage.code) {
        case 'nl-NL':
        case 'nl-BE':
          break;
        case 'fr-FR':
          this.kosmosURL += 'fr/';
          break;
        case 'zh-CN':
          this.kosmosURL += 'zh-hans/'
          break;
        case 'it-IT':
          this.kosmosURL += 'it/';
          break;
        case 'ru-RU':
          this.kosmosURL += 'ru/';
          break;
        case 'es-ES':
          this.kosmosURL += 'es/';
          break;
        default: 
          this.kosmosURL += 'en/';
        }
    })
  }

  ngAfterViewInit() {
    this.loadMenuState();
  }

  // click on nav section or anchor
  onRouterLinkClick(sectionId: string, parentSectionId: string, event: Event) {
    if (event) {
      event.stopPropagation();
    }
  }

  // check the state of sections
  loadMenuState() {

    this.collapsableSections.forEach(section => {
      section.getState();
    });
  }

  navExpand() {
    this.expanded = true;
    this.navPin();

    this.timer.subscribe(
      () => {
        this.loadMenuState();
      }
    );
  }

  navCollapse() {
    this.expanded = false;
    this.navPin();
  }

  navPin() {
    this.pin = !this.pin;
    this.cookies.set('navPin', String(this.pin));
  }
}
