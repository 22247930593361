import { Modes } from "./clock";

export class AuctionClusterAuctionMode {
    auctionClusterId: number;
    auctionClusterAuctionModeId: number;
    clockMode: Modes;
    name: string;

    //Displayable fields
    nameText: string;
    clockModeText: string;
}