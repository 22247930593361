import { Component, HostListener, Injector, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular';

// components
import { ItemDetailsComponent } from '../../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../../shared/components/save-confirmation/save-confirmation.component';

// models
import { Language } from '../../../shared/models/language';
import { AuctionClusterAuctionMode } from '../../../shared/models/auction-cluster-auction-mode';
import { Modes } from '../../../shared/models/clock';

// services
import { AuctionClusterService } from '../../shared/services/auction-cluster.service';
import { LanguageService } from '../../../shared/services/language.service';

const ESC_KEYCODE = 27;

@Component({
  selector: 'auction-cluster-auction-mode-component',
  templateUrl: './auction-cluster-auction-mode.component.html',
  styleUrls: ['./auction-cluster-auction-mode.component.scss']
})
export class AuctionClusterAuctionModeComponent extends ItemDetailsComponent<AuctionClusterAuctionMode> implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  languages: Array<Language> = [];
  formDirty: boolean = false;  
  modes: any = Modes;
  auctionClusterId: number;

  @ViewChild('auctionModeName') auctionModeName;
  @ViewChild('editAuctionClusterAuctionModeForm', { read: DxFormComponent}) auctionClusterAuctionModeForm: DxFormComponent;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;

  @Output() onUpdated = new EventEmitter();
  @Output() onCancelled = new EventEmitter();
  @Output() onAdded = new EventEmitter();
 
  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterService,
    private languageService: LanguageService,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.modes = Object.keys(this.modes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.modes[key], value: Number(key) })); // tslint:disable-line:max-line-length
  }
  
  ngOnInit() {
    this.model = new AuctionClusterAuctionMode();
    this.model.auctionClusterId = this.auctionClusterId;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  
  public onCancel() {
    this.isOpened = false;
    this.auctionModeName.resetLanguageButtons();
    this.errorMessage = null;
    if(this.formDirty) {
      this.onCancelled.emit();
    }
  }
  
  open(auctionMode: AuctionClusterAuctionMode, languages: Array<Language>, auctionClusterId: number) {
    this.isOpened = true;
    this.formDirty = false;
    this.languages = languages;
    this.auctionClusterId = auctionClusterId;

    if (auctionMode == null) {
      this.isEditMode = false;
      this.model = new AuctionClusterAuctionMode();
    }
    else {
      this.isEditMode = true;
      this.model = auctionMode;
    }
    
    this.createEmptyObjectForTranslatableFields();
    this.spinner.hide();
  }
  
  save() {
    if (this.isEditMode) {
        this.spinner.show();
        this.dataService.editAuctionClusterAuctionMode(this.auctionClusterId, this.model)
            .subscribe((res: any) => {
                this.model = new AuctionClusterAuctionMode();
                this.close(true);
                this.errorMessage = null;
                this.spinner.hide();
                this.onUpdated.emit();
            },
                error => {
                    this.errorService.show(error);
                    this.spinner.hide();
                });
    } else {
        this.spinner.show();
        this.model.auctionClusterId = this.auctionClusterId;

        this.dataService.saveAuctionClusterAuctionMode(this.auctionClusterId, this.model)
            .subscribe((res: any) => {
                this.model = new AuctionClusterAuctionMode();
                this.close(true);
                this.errorMessage = null;
                this.spinner.hide();
                this.onAdded.emit();
            },
                error => {
                    this.errorService.show(error);
                    this.spinner.hide();
                });
    }
  }

  createEmptyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });
    if(!this.model.name) {
      this.model.name = JSON.stringify(emptyTranslation);
    }
  }
  
  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }
  
  onFieldDataChanged(e) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup'){
      if (!this.formDirty) this.formDirty = true;
    }
  }
  
  onChangeTranslationField = (e: any, fieldName: string) => {
    this.formDirty = true;
    this.auctionClusterAuctionModeForm.instance.updateData(fieldName, this.auctionModeName.data);    
  }
  
  translateSelectBoxes = (item) => {
    if (item) {
      let label = 'CLOCK.' + item.name;
      this.translate.get(label).subscribe((res: string) => {
        label = res;
      });
      return label;
    }
  }
}
