<div dir="{{rtlEnabled ? 'rtl' : 'ltr'}}" class="main-container content-component" [class.test]="baseUrl && (baseUrl.includes('test') || baseUrl.includes('qa'))">
  <div class="content-container">
    <div class="content-area">
      <header-component></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow" dir="left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a [routerLink]="['/core/home']" class="logo-wrapper" (click)="onRouterLinkClick()">
          <img *ngIf="!sidebarLogo" src="assets/images/Aucxis_w.png" class="aucxis-logo" />
          <img *ngIf="!sidebarLogo" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarLogo" [src]="sidebarLogo" />
        </a>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('SupplierPermissions.3', id)">
          <a routerLinkActive="active" [routerLink]="['/supplier/users/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.USERS' | translate }}</p>
          </a>
        </div>

        <!--<collapsable-section sectionId="public" [caption]="'CONTENT.PUBLIC_MENU_ITEM' | translate" (clicked)="onRouterLinkClick('public')">
          <ul class="nav-list">
            <li>
              <a [routerLink]="['/supplier/supplyforecast/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('public')">
                {{ 'CONTENT.SUPPLY_FORECAST' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>-->

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('SupplierPermissions.4', id)">
          <a routerLinkActive="active" [routerLink]="['/supplier/clocks/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('supplier-clocks')">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SUPPLIER.SALES' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item">
          <a routerLinkActive="active" [routerLink]="['/supplier/supplyforecast/' + id]" class="nav-link au-sidebar-menu-item-link public-supply-forecast-urk" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
            <p class="au-sidebar-menu-item-text">{{ 'CONTENT.SUPPLY_FORECAST' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('SupplierPermissions.2', id)">
          <a routerLinkActive="active" [routerLink]="['/supplier/reports/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
            <p class="au-sidebar-menu-item-text">{{ 'REPORTING.TITLE' | translate }}</p>
          </a>
        </div>
        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('SupplierPermissions.5', id)">
          <a routerLinkActive="active" [routerLink]="['/supplier/forecastcatalogs/' + id + '/ac/' + auctionClusterId]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
            <p class="au-sidebar-menu-item-text">{{ 'AUCTION.FORECAST_CATALOG_MANAGEMENT' | translate }}</p>
          </a>
        </div>

        <ng-container *ngFor="let item of menuItems">
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems">
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <li class="au-sidebar-menu-item">
                    <a *ngIf="!subItem.openInNewTab"
                       [routerLink]="['/supplier/' + id + '/menu-item/' + subItem.externalAppId]"
                       routerLinkActive="active"
                       class="nav-link au-sidebar-menu-item-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>

                    <a *ngIf="subItem.openInNewTab"
                       [href]="subItem.applicationUrl"
                       [target]="'_blank'"
                       routerLinkActive="active"
                       class="nav-link au-sidebar-menu-item-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <div class="au-sidebar-menu-item">
              <a *ngIf="!item.openInNewTab"
                 [routerLink]="['/supplier/' + id + '/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>

              <a *ngIf="item.openInNewTab"
                 [href]="item.applicationUrl"
                 [target]="'_blank'"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <div class="au-sidebar-menu-item">
              <a [routerLink]="['/supplier/' + id + '/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>
        </ng-container>

      </section>
      <div class="au-sidebar-bottom-brand-area">
        <a *ngIf="expanded || pin" [href]="kosmosURL" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/kosmos.png" />
        </a>
        <version-component></version-component>
      </div>

      <section class="sidenav-content" *ngIf="!expanded && !pin">
        <a [routerLink]="['/core/home']" class="small-logo-wrapper">
          <!--<img src="assets/images/europool_logo_small.png" />-->
        </a>
        <div class="expand-btn" (click)="navExpand()">
          <clr-icon shape="arrow right" size="32"></clr-icon>
        </div>
        <a [href]="kosmosURL" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/kosmos_vert.png" />
        </a>
      </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>
