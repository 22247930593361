<div class="generic-product-property-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body" [ngClass]="{'report-body': isReport}">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>
      <form #detailsForm="ngForm" clrForm>
        <div class="clr-form-control" *ngIf="showName">
          <label class="clr-control-label">{{ 'SHARED.NAME' | translate }}</label>
          <div class="clr-control-container">
            <translatable-field [(data)]="model.name" [name]="'name' + model.productPropertyId" [clusterLanguages]="languages" #name></translatable-field>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="productProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY_NAME' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="productProperty" name="productProperty" [(ngModel)]="model.productPropertyId" (ngModelChange)="onMasterDataChanged()" required>
                <option></option>
                <option *ngFor="let property of productProperties" [ngValue]="property.productPropertyId">{{property.productPropertyNameText}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="isMasterData && isMasterDataFieldNeeded">
          <label for="masterDataField" class="clr-control-label">{{ 'SHARED.MASTER_DATA_FIELD' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="masterDataField" name="masterDataField" [(ngModel)]="model.masterDataListFieldId" required>
                <option></option>
                <option *ngFor="let field of masterDataListFields" [ngValue]="field.masterDataListFieldId">{{ field.name | translateJson }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="isMasterData && isReport">
          <label for="defaultMd" class="clr-control-label">{{ 'SHARED.DEFAULT_VALUE' | translate }}</label>
          <masterdata-dropdown id="defaultMd" name="defaultMd"
                               [auctionClusterId]="auctionClusterId"
                               [listId]="masterDataListId"
                               [(selectedRowId)]="model.defaultMasterDataListRowId">
          </masterdata-dropdown>
        </div>

        <div *ngIf="isDate && isReport">
          <div class="clr-form-control">
            <label class="clr-control-label"></label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="showTodayDateSelection" (change)="dateSelectionChanged();" [(ngModel)]="model.showTodayDateSelection" />
              <label>{{ 'PRODUCT.PROPERTY.SHOW_TODAY' | translate }}</label>
            </clr-checkbox-wrapper>
          </div>

          <div class="clr-form-control">
            <label class="clr-control-label"></label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="showYesterdayDateSelection" (change)="dateSelectionChanged();" [(ngModel)]="model.showYesterdayDateSelection" />
              <label>{{ 'PRODUCT.PROPERTY.SHOW_YESTERDAY' | translate }}</label>
            </clr-checkbox-wrapper>
          </div>

          <div class="clr-form-control">
            <label class="clr-control-label"></label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="showTomorrowDateSelection" (change)="dateSelectionChanged();" [(ngModel)]="model.showTomorrowDateSelection" />
              <label>{{ 'PRODUCT.PROPERTY.SHOW_TOMORROW' | translate }}</label>
            </clr-checkbox-wrapper>
          </div>

          <div class="clr-form-control">
            <label class="clr-control-label"></label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="showLastWeekDateSelection" (change)="dateSelectionChanged();" [(ngModel)]="model.showLastWeekDateSelection" />
              <label>{{ 'PRODUCT.PROPERTY.SHOW_LAST_WEEK' | translate }}</label>
            </clr-checkbox-wrapper>
          </div>

          <div class="clr-form-control">
            <label class="clr-control-label"></label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="showRangeDateSelection" (change)="dateSelectionChanged();" [(ngModel)]="model.showRangeDateSelection" />
              <label>{{ 'PRODUCT.PROPERTY.SHOW_RANGE' | translate }}</label>
            </clr-checkbox-wrapper>
          </div>

          <div class="clr-form-control">
            <label class="clr-control-label"></label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="showDateDateSelection" (change)="dateSelectionChanged();" [(ngModel)]="model.showDateDateSelection" />
              <label>{{ 'PRODUCT.PROPERTY.SHOW_DATE' | translate }}</label>
            </clr-checkbox-wrapper>
          </div>
        </div>

        <div *ngIf="isDate && isReport">
          <div class="clr-form-control">
            <label for="dateType" class="clr-control-label">{{ 'SHARED.DEFAULT_VALUE' | translate }}</label>
            <div class="clr-control-container">
              <div class="clr-select-wrapper">
                <select id="dateType" name="dateType" [(ngModel)]="model.dateType">
                  <option *ngIf="model.showTodayDateSelection" value="0">{{ 'SHARED.TODAY' | translate }}</option>
                  <option *ngIf="model.showYesterdayDateSelection" value="1">{{ 'SHARED.YESTERDAY' | translate }}</option>
                  <option *ngIf="model.showLastWeekDateSelection" value="2">{{ 'SHARED.LAST_WEEK' | translate }}</option>
                  <option *ngIf="model.showRangeDateSelection" value="3">{{ 'SHARED.RANGE' | translate }}</option>
                  <option *ngIf="model.showTomorrowDateSelection" value="4">{{ 'SHARED.TOMORROW' | translate }}</option>
                  <option *ngIf="model.showDateDateSelection" value="5">{{ 'SHARED.DATE' | translate }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="model.dateType === '3' && isReport">
          <input type="text" name="dateRange" placeholder="" [(ngModel)]="defaultDateRange" [selectMode]="'range'"
                 [owlDateTimeTrigger]="dtRange1" [owlDateTime]="dtRange1">
          <owl-date-time [pickerType]="'calendar'" #dtRange1></owl-date-time>
        </div>

        <div class="clr-form-control" *ngIf="isReport">
          <label for="filterStoredProcedure" class="clr-control-label">{{ 'PRODUCT.PROPERTY.FILTER_STORED_PROCEDURE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input id="filterStoredProcedure" type="text" name="filterStoredProcedure" class="clr-input" [(ngModel)]="model.filterStoredProcedure" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="isReport && isMasterData">
          <label for="multiSelect" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MULTI_SELECT' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="multiSelect" type="checkbox" clrCheckbox name="multiSelect" [(ngModel)]="model.multiSelect" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="propertyGroupingShowing">
          <label for="automaticGroupingProperty" class="clr-control-label">{{ (showOnlyAutomaticGrouping ? 'PRODUCT.PROPERTY.GROUPING_PROPERTY' : 'PRODUCT.PROPERTY.AUTOMATIC_GROUPING_PROPERTY') | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="automaticGroupingProperty" name="automaticGroupingProperty" [(ngModel)]="model.automaticProductPropertyGroupingSelectionId">
                <option></option>
                <option *ngFor="let prop of automaticGroupingProperties" [ngValue]="prop.id">{{ prop.label }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="propertyGroupingShowing && !showOnlyAutomaticGrouping">
          <label for="manualGroupingProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MANUAL_GROUPING_PROPERTY' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="manualGroupingProperty" name="manualGroupingProperty" [(ngModel)]="model.manualProductPropertyGroupingSelectionId">
                <option></option>
                <option *ngFor="let prop of manualGroupingProperties" [ngValue]="prop.id">{{ prop.label }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showFilterOnCurrentLotProperty">
          <label for="filterOnCurrentLot" class="clr-control-label">{{ 'PRODUCT.PROPERTY.FILTER_ON_CURRENT_LOT' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="filterOnCurrentLot" type="checkbox" clrCheckbox name="filterOnCurrentLot" [(ngModel)]="model.filterOnCurrentLot" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showLastInFullScreenProperty">
          <label for="showLastInFullScreen" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SHOW_LAST_IN_FULL_SCREEN' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="showLastInFullScreen" type="checkbox" clrCheckbox name="showLastInFullScreen" [(ngModel)]="model.showLastInFullScreen" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showIncludeInSummaryProperty">
          <label for="includeInSummary" class="clr-control-label">{{ 'PRODUCT.PROPERTY.INCLUDE_IN_SUMMARY' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="includeInSummary" type="checkbox" clrCheckbox name="includeInSummary" [(ngModel)]="model.includeInSummary" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showIncludeInSummaryProperty && !model.includeInSummary">
          <label class="clr-control-label">{{ 'PRODUCT.PROPERTY.VALUE_TO_SHOW_IN_SUMMARY' | translate }}</label>
          <div class="clr-control-container">
            <translatable-field [textRequired]="false"
                                [(data)]="model.valueToShowInSummary"
                                [name]="'valueToShowInSummary'"
                                [clusterLanguages]="languages" #valueToShowInSummary></translatable-field>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showColRow">
          <label for="rowNumber" class="clr-control-label">{{ 'PRODUCT.PROPERTY.ROW_NUMBER' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input class="clr-input" type="number" name="rowNumber" id="rowNumber" [(ngModel)]="model.rowNumber" #rowNumber="ngModel" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showColRow">
          <label for="columnNumber" class="clr-control-label">{{ 'PRODUCT.PROPERTY.COLUMN_NUMBER' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input class="clr-input" type="number" name="columnNumber" id="columnNumber" [(ngModel)]="model.columnNumber" #columnNumber="ngModel" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showWidth">
          <label for="width" class="clr-control-label">{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input class="clr-input" type="number" name="width" id="width" [(ngModel)]="model.width" #width="ngModel" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showComparable">
          <label for="isComparable" class="clr-control-label">{{ 'PRODUCT.PROPERTY.IS_COMPARABLE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="isComparable" type="checkbox" clrCheckbox name="isComparable" [(ngModel)]="model.isComparable" />
            </div>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="isDeletable" class="clr-control-label">{{ 'PRODUCT.PROPERTY.IS_DELETABLE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="isDeletable" type="checkbox" clrCheckbox name="isDeletable" [(ngModel)]="model.isDeletable" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="showRequired">
          <label for="required" class="clr-control-label">{{ 'PRODUCT.PROPERTY.REQUIRED' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="required" type="checkbox" clrCheckbox name="required" [(ngModel)]="model.required" />
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="isReport">
          <label for="filterRequired" class="clr-control-label">{{ 'PRODUCT.PROPERTY.FILTERREQUIRED' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="filterRequired" type="checkbox" clrCheckbox name="filterRequired" [(ngModel)]="model.filterRequired" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{ (isEditMode ? 'SAVE' : 'ADD') | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
