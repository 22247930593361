<div class="auction-cluster-auction-mode-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #auctionClusterMode
  [width]="600"
  [showTitle]="true"
  title="{{ 'AUCTIONS_CLUSTERS.AUCTION_MODES' | translate}}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #editAuctionClusterAuctionModeForm
                 id="editAuctionClusterAuctionModeForm"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
                 
          <dxi-item dataField="clockMode" editorType="dxSelectBox"
                    [editorOptions]="{ items: modes, valueExpr:'value', displayExpr: translateSelectBoxes }">
            <dxo-label text="{{ 'SHARED.MODE' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SHARED.MODE' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item itemType="group">
            <dxi-item dataField="name"
                      [template]="'auctionModeNameField'">
            <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SHARED.MODE' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
            </dxi-validation-rule>
            </dxi-item>
          </dxi-item>

          <div *dxTemplate="let data of 'auctionModeNameField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [textRequired]="false" [caption]="'SHARED.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #auctionModeName></translatable-field>  
          </div>
        </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnAuctionClusterAuctionModeSubmit">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'SHARED.LEAVE_PAGE' | translate }}" buttonCloseText="{{ 'SHARED.OK' | translate }}" cancellable="true" (close)="onCancel();"></save-confirmation-component>
