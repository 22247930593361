<div dir="{{rtlEnabled ? 'rtl' : 'ltr'}}" class="main-container content-component" [class.efice]="baseUrl && baseUrl.includes('efice.com')" [class.test]="baseUrl && (baseUrl.includes('test') || baseUrl.includes('qa'))">
  <div class="content-container">
    <div class="content-area">
      <header-component></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a [routerLink]="['/auction/supplymonitor/'+ id]" class="logo-wrapper" (click)="onRouterLinkClick()">
          <img *ngIf="!sidebarLogo" src="assets/images/Aucxis_w.png" class="aucxis-logo" />
          <img *ngIf="!sidebarLogo" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarLogo" [src]="sidebarLogo" />
        </a>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.1', id)||tokenService.permissionMet('AuctionClusterPermissions.16', id)" sectionId="ac-users" [caption]="'SHARED.USERS_ROLES_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('ac-users')">
          <ul class="nav-list">
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.1', id)">
              <a [routerLink]="['/auction/users/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-users')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-user"></i>
                <p class="au-sidebar-menu-item-text">{{ 'SHARED.USERS' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.16', id)">
              <a [routerLink]="['/auction/roles/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-users')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-card"></i>
                <p class="au-sidebar-menu-item-text">{{ 'ROLES.TITLE' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.16', id)">
              <a [routerLink]="['/auction/reportingroles/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-users')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-card"></i>
                <p class="au-sidebar-menu-item-text">{{ 'AUCTION.REPORTING_ROLES' | translate }}</p>
              </a>
            </li>
          </ul>
        </collapsable-section>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.8', id)">
          <a routerLinkActive="active" [routerLink]="['/auction/buyer/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-money"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.BUYER_MANAGEMENT' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.9', id)">
          <a routerLinkActive="active" [routerLink]="['/auction/supplier/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-cart"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.SUPPLIER_MANAGEMENT' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)">
          <a routerLinkActive="active" [routerLink]="['/auction/auction/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-mediumiconslayout"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.AUCTION_MANAGEMENT' | translate }}</p>
          </a>
        </div>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.5', id) || tokenService.permissionMet('AuctionClusterPermissions.17', id)" sectionId="ac-locations" [caption]="'AUCTION.LOCATION_AND_ZONE_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('ac-locations')">
          <ul class="nav-list">
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.5', id)">
              <a [routerLink]="['/auction/locations/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-locations')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-map"></i>
                <p class="au-sidebar-menu-item-text">{{ 'LOCATIONS.TITLE' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.17', id)">
              <a [routerLink]="['/auction/zones/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-locations')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-codeblock"></i>
                <p class="au-sidebar-menu-item-text">{{ 'ZONES.TITLE' | translate }}</p>
              </a>
            </li>
          </ul>
        </collapsable-section>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.22', id)">
          <a routerLinkActive="active" [routerLink]="['/auction/gallery/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-smalliconslayout"></i>
            <p class="au-sidebar-menu-item-text">{{ 'GALLERY.TITLE' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.3', id) || tokenService.permissionMet('AuctionPermissions.10', id) || tokenService.permissionMet('AuctionPermissions.3', id)">
          <a routerLinkActive="active"
             [routerLink]="['/auction/clocks/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-clock"></i>
            <p class="au-sidebar-menu-item-text">{{ 'CLOCKS.TITLE' | translate }}</p>
          </a>
        </div>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id) || tokenService.permissionMet('AuctionClusterPermissions.6', id) || tokenService.permissionMet('AuctionClusterPermissions.7', id)" sectionId="ac-products" [caption]="'AUCTION.PRODUCTS_AND_MASTER_DATA' | translate" (clicked)="onRouterLinkClick('ac-products')">
          <ul class="nav-list">
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)">
              <a [routerLink]="['/auction/products/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-products')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-product"></i>
                <p class="au-sidebar-menu-item-text">{{ 'AUCTION.PRODUCT_MANAGEMENT' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.6', id)">
              <a [routerLink]="['/auction/masterdata/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-products')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-fields"></i>
                <p class="au-sidebar-menu-item-text">{{ 'SHARED.MASTER_DATA' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.7', id)">
              <a [routerLink]="['/auction/lookuptables/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-products')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-verticalaligncenter"></i>
                <p class="au-sidebar-menu-item-text">{{ 'AUCTION.LOOKUP_TABLES' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)">
              <a [routerLink]="['/auction/masks/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-products')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-unselectall"></i>
                <p class="au-sidebar-menu-item-text">{{ 'AUCTION.MASKS' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)">
              <a [routerLink]="['/auction/calculations/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-products')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-formula"></i>
                <p class="au-sidebar-menu-item-text">{{ 'CALCULATIONS.TITLE' | translate }}</p>
              </a>
            </li>
          </ul>
        </collapsable-section>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.4', id) || tokenService.permissionMet('AuctionPermissions.4', id) || tokenService.permissionMet('AuctionClusterPermissions.18', id) || tokenService.permissionMet('AuctionPermissions.18', id)" sectionId="ac-catalogs" [caption]="'AUCTION.CATALOG_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('ac-catalogs')">
          <ul class="nav-list">
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.4', id) || tokenService.permissionMet('AuctionPermissions.4', id)">
              <a [routerLink]="['/auction/supplycatalogs/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-catalogs')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-cart"></i>
                <p class="au-sidebar-menu-item-text">{{ 'AUCTION.SUPPLY_CATALOG_MANAGEMENT' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.18', id) || tokenService.permissionMet('AuctionPermissions.18', id)">
              <a [routerLink]="['/auction/forecastcatalogs/' + id]" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('ac-catalogs')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-event"></i>
                <p class="au-sidebar-menu-item-text">{{ 'AUCTION.FORECAST_CATALOG_MANAGEMENT' | translate }}</p>
              </a>
            </li>
          </ul>
        </collapsable-section>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.19', id) || tokenService.permissionMet('AuctionPermissions.19', id)">
          <a routerLinkActive="active"
             [routerLink]="['/auction/lottery/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-activefolder"></i>
            <p class="au-sidebar-menu-item-text">{{ 'AUCTION.LOTTERY' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item">
          <a routerLinkActive="active" [routerLink]="['/auction/supplyforecast/' + id]" class="nav-link au-sidebar-menu-item-link public-supply-forecast-urk" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-event"></i>
            <p class="au-sidebar-menu-item-text">{{ 'CONTENT.SUPPLY_FORECAST' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.12', id) || tokenService.permissionMet('AuctionPermissions.12', id)">
          <a routerLinkActive="active"
             [routerLink]="['/auction/supplymonitor/'+ id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-chevrondoubleright"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SUPPLY_MONITOR.TITLE' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.15', id) || tokenService.permissionMet('AuctionPermissions.15', id)">
          <a routerLinkActive="active"
             [routerLink]="['/auction/transactionmonitor/'+ id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-movetofolder"></i>
            <p class="au-sidebar-menu-item-text">{{ 'TRANSACTION_MONITOR.TITLE' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.13', id)">
          <a routerLinkActive="active"
             [routerLink]="['/auction/ticketprinters/'+ id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-print"></i>
            <p class="au-sidebar-menu-item-text">{{ 'TICKET_PRINTER.TITLE' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.21', id)">
          <a routerLinkActive="active"
             [routerLink]="['/auction/terms/'+ id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-textdocument"></i>
            <p class="au-sidebar-menu-item-text">{{ 'TERMS_AND_CONDITIONS.TITLE' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.11', id) || tokenService.permissionMet('AuctionPermissions.11', id)">
          <a routerLinkActive="active" [routerLink]="['/auction/reports/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-bulletlist"></i>
            <p class="au-sidebar-menu-item-text">{{ 'REPORTING.TITLE' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.23', id)">
          <a routerLinkActive="active" [routerLink]="['/auction/notifications/' + id]" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-warning"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.NOTIFICATIONS' | translate }}</p>
          </a>
        </div>

        <ng-container *ngFor="let item of menuItems">
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems">
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <li class="au-sidebar-menu-item">
                    <a *ngIf="!subItem.openInNewTab"
                       [routerLink]="['/auction/' + id + '/menu-item/' + subItem.externalAppId]"
                       routerLinkActive="active"
                       class="nav-link au-sidebar-menu-item-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>

                    <a *ngIf="subItem.openInNewTab"
                       [href]="subItem.applicationUrl"
                       [target]="'_blank'"
                       routerLinkActive="active"
                       class="nav-link au-sidebar-menu-item-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <div class="au-sidebar-menu-item">
              <a *ngIf="!item.openInNewTab"
                 [routerLink]="['/auction/' + id + '/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>

              <a *ngIf="item.openInNewTab"
                 [href]="item.applicationUrl"
                 [target]="'_blank'"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <div class="au-sidebar-menu-item">
              <a [routerLink]="['/auction/' + id + '/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>
        </ng-container>
      </section>

      <div class="au-sidebar-bottom-brand-area">
        <a *ngIf="expanded || pin" [href]="kosmosURL" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/kosmos.png" />
        </a>
        <version-component></version-component>
      </div>

      <section class="sidenav-content" *ngIf="!expanded && !pin">
        <a [routerLink]="['/core/home']" class="small-logo-wrapper">
          <!--<img src="assets/images/europool_logo_small.png" />-->
        </a>
        <div class="expand-btn" (click)="navExpand()">
          <clr-icon shape="arrow right" size="42"></clr-icon>
        </div>
        <a [href]="kosmosURL" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/kosmos_vert.png" />
        </a>
      </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>
